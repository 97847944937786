import type { IconProps } from "../../types";

export const InfoIcon = ({ size = 24, fill = "none", ...props }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      {...props}
    >
      <rect width="24" height="24" fill="white" />
      <path
        d="M21 12C21 7.02834 16.9702 3 12 3C7.02979 3 3 7.02834 3 12C3 16.9688 7.02979 21 12 21C16.9702 21 21 16.9688 21 12ZM12 10.1855C11.078 10.1855 10.3306 9.43808 10.3306 8.51613C10.3306 7.59417 11.078 6.84678 12 6.84678C12.922 6.84678 13.6694 7.59417 13.6694 8.51613C13.6694 9.43808 12.922 10.1855 12 10.1855ZM10.4151 16.1859L10.6843 11.2505C10.6969 11.0195 10.8878 10.8387 11.1191 10.8387H12.8809C13.1122 10.8387 13.3031 11.0195 13.3157 11.2505L13.5849 16.1859C13.5985 16.4354 13.3999 16.6452 13.1501 16.6452H10.8499C10.6001 16.6452 10.4015 16.4354 10.4151 16.1859Z"
        fill="#6A6A6A"
      />
    </svg>
  );
};
