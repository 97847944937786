export const EyeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#60646C"
      className="h-4 w-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
  );
};
export const EyeIconInactive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      fill="#666666"
    />
    <path
      d="M15.3394 7.45625C14.5125 6.1775 13.4397 5.09125 12.2372 4.31469C10.9069 3.45469 9.43813 3 7.99 3C6.66125 3 5.35469 3.37969 4.10656 4.12844C2.83375 4.89188 1.68062 6.00719 0.679061 7.44312C0.565994 7.60541 0.503723 7.79764 0.500162 7.9954C0.496601 8.19315 0.55191 8.3875 0.659061 8.55375C1.48437 9.84531 2.54656 10.9331 3.73031 11.6991C5.06313 12.5625 6.4975 13 7.99 13C9.44969 13 10.9216 12.5491 12.2463 11.6962C13.4481 10.9222 14.5188 9.83187 15.3425 8.5425C15.446 8.38012 15.5007 8.19146 15.5001 7.99892C15.4996 7.80638 15.4438 7.61804 15.3394 7.45625ZM8.00063 11C7.40728 11 6.82726 10.8241 6.33392 10.4944C5.84057 10.1648 5.45605 9.69623 5.22899 9.14805C5.00192 8.59987 4.94251 7.99667 5.05827 7.41473C5.17403 6.83279 5.45975 6.29824 5.87931 5.87868C6.29886 5.45912 6.83341 5.1734 7.41536 5.05764C7.9973 4.94189 8.6005 5.0013 9.14868 5.22836C9.69686 5.45542 10.1654 5.83994 10.495 6.33329C10.8247 6.82664 11.0006 7.40666 11.0006 8C10.9997 8.79537 10.6834 9.5579 10.1209 10.1203C9.55853 10.6827 8.796 10.9991 8.00063 11Z"
      fill="#666666"
    />
  </svg>
);

export const EyeIconActive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      fill="#121212"
    />
    <path
      d="M15.3394 7.45625C14.5125 6.1775 13.4397 5.09125 12.2372 4.31469C10.9069 3.45469 9.43813 3 7.99 3C6.66125 3 5.35469 3.37969 4.10656 4.12844C2.83375 4.89188 1.68062 6.00719 0.679061 7.44312C0.565994 7.60541 0.503723 7.79764 0.500162 7.9954C0.496601 8.19315 0.55191 8.3875 0.659061 8.55375C1.48437 9.84531 2.54656 10.9331 3.73031 11.6991C5.06313 12.5625 6.4975 13 7.99 13C9.44969 13 10.9216 12.5491 12.2463 11.6962C13.4481 10.9222 14.5188 9.83187 15.3425 8.5425C15.446 8.38012 15.5007 8.19146 15.5001 7.99892C15.4996 7.80638 15.4438 7.61804 15.3394 7.45625ZM8.00063 11C7.40728 11 6.82726 10.8241 6.33392 10.4944C5.84057 10.1648 5.45605 9.69623 5.22899 9.14805C5.00192 8.59987 4.94251 7.99667 5.05827 7.41473C5.17403 6.83279 5.45975 6.29824 5.87931 5.87868C6.29886 5.45912 6.83341 5.1734 7.41536 5.05764C7.9973 4.94189 8.6005 5.0013 9.14868 5.22836C9.69686 5.45542 10.1654 5.83994 10.495 6.33329C10.8247 6.82664 11.0006 7.40666 11.0006 8C10.9997 8.79537 10.6834 9.5579 10.1209 10.1203C9.55853 10.6827 8.796 10.9991 8.00063 11Z"
      fill="#121212"
    />
  </svg>
);

export const CrossedEyeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#60646C"
      className="h-4 w-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
      />
    </svg>
  );
};
