import * as React from "react";

import { cn } from "~/lib/ui";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "flex h-[52px] w-full rounded-[4px] border border-black  bg-transparent bg-white px-3 py-2 text-base font-normal transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:border-2 focus-visible:border-black focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid='true']:border-error aria-[invalid='true']:focus-visible:border-error",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
