export const TickIcon = ({
  className,
  color = "white",
  width = "12",
  height = "12",
}: {
  className?: string;
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <div className={className}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2897 0.968954C11.6749 1.22082 11.783 1.73727 11.5312 2.12247L5.86447 10.7892C5.73093 10.9934 5.51457 11.1288 5.27251 11.1597C5.03045 11.1906 4.78701 11.1138 4.60643 10.9497L0.939768 7.61638C0.599221 7.30679 0.574128 6.77975 0.883715 6.43921C1.1933 6.09866 1.72034 6.07355 2.06089 6.38315L5.00438 9.05905L10.1362 1.21039C10.3881 0.825194 10.9045 0.717101 11.2897 0.968954Z"
          fill={color}
        />
      </svg>
    </div>
  );
};
